<template>
  <div class="super-discount-content">
    <image-header-page :image-header-props="imageHeaderProps">
      <template v-slot:header>
        <div class="flex-column full-height full-width align-center">
          <div class="flex-row full-height full-width" :style="{alignItems: 'flex-end'}">
            <div class="ri-small-display" :style="{textAlign: 'right', paddingBottom:'8px'}">סידרנו לך הנחה על קניות בסופר</div>
            <img :style="{width: '160px', height: '160px'}" :src="require('@/assets/super-discount/groceries.png')"/>
          </div>
          <div :style="{height: '26px'}"></div>
        </div>
      </template>
      <template>
        <div class="flex-column full-width full-height padding-horizontal-m">
          <div class="margin-top-l ri-medium-bold-headline">
            תווי קניה בהנחה של 7%
          </div>
          <div class="ri-large-body margin-top-s">
            חיסכון של מאות שקלים בחודש בסופרים הבאים:
          </div>
          <img :src="require('@/assets/super-discount/all-logos-new.png')"
          :style="{width: '320px'}" class="margin-top-xs"/>
          <div :style="{backgroundColor: colors.lightBlue, borderRadius: '18px', color: colors.darkGreen, fontWeight: 'bold'}"
               class="padding-vertical-sm padding-horizontal-sm ri-medium-headline ri-font-weight-bold margin-top-l margin-bottom-ml" >
            <div>בקרוב: הנחות בלעדיות</div>
            <div class="flex-row align-center">
              <span :style="{whiteSpace: 'nowrap'}">גם ברשת</span>
              <img :src="require('@/assets/super-discount/rami-logo.png')" :style="{height: '23px', marginRight: '8px'}" />
            </div>
            <div v-if="showAddToRamiLeviWaiting" :style="{border: `1px solid ${colors.darkGreen}`, width: 'fit-content', cursor: 'pointer'}"
                 class="border-radius-small padding-horizontal-s padding-vertical-xs margin-top-s ri-bold-label"
            @click="addToWaitingList">
              עדכנו אותי כשאפשר לקנות תווים
            </div>
            <div v-else class="border-radius-small padding-horizontal-s padding-vertical-xs margin-top-s ri-bold-label flex-row align-center">
              <icon icon-name="check" :color="colors.darkGreen" weight="bold" size="18px" fill  class="margin-left-xxs"/>
              <span>מצויין, נעדכן אותך בקרוב</span>
            </div>
          </div>
        </div>
      </template>
    </image-header-page>
    <div class="summary-row">
      <expandable-summary-row  text-style="medium"
                               :with-shadow=false
                               :is-expandable=true
                               :header-text="'כמה פרטים נוספים'"
                               @expanded="detailsExpanded">
        <template v-slot:content>
          <div class="answer margin-top-m">
            <ul class="bullets">
              <li>ניתן לקנות תווים בין 50 ל־1,000 ₪.</li>
              <li>את התו תקבלו ב-SMS למספר שתקלידו.</li>
              <li>בשימוש התו בקניות אונליין, אפשר להשתמש רק בתו אחד בכל בקנייה.</li>
              <li>התווים בתוקף ל־5.5 שנים, וניתן לממש בקניות נפרדות.</li>
              <li>רייזאפ תקבל מ׳יינות ביתן׳ עמלה של 1.5% עבור כל קניית תו באתר של ״תו פלוס״.</li>
              <li>רכישת התו, הפעלתו ומימושו באחריות ׳יינות ביתן׳ - <span class="link" @click="termsClicked">כאן אפשר לקרוא את תנאי השימוש.</span></li>
              <li>אנחנו עובדים על שיתופי פעולה נוספים, נעדכן!</li>
            </ul>
          </div>
        </template>
      </expandable-summary-row>
    </div>
    <div class="sticky-container">
      <separator class="separator" />
      <div class="button-container">
        <div class="ri-large-bodyl margin-bottom-m margin-top-l">
          לניצול ההטבה הזינו במסך הבא את מספר הארגון:
        </div>
        <div class="margin-bottom-ml">
          <span class="ri-font-40 margin-left-xs">{{organizationCode}}</span>
          <span v-if="showCopyCode" :style="{display:'inline-flex', color: colors.blue, cursor: 'pointer', alignItems: 'flex-end'}"
                @click="copyCode" class="ri-label">
            <icon icon-name="content_copy" :color="colors.blue" size="18px"  class="margin-left-xxs padding-bottom-xxs" />
            <span>להעתיק</span>
          </span>
          <span v-else :style="{display:'inline-flex'}" class="ri-label">
            <icon icon-name="check" :color="colors.green" weight="bold" size="18px" fill  class="margin-left-xxs"/>
            <span  :style="{color: colors.green}">הועתק</span>
          </span>

        </div>
        <riseup-button class="button"
                       :action="buttonClicked"
                       :title="'אני רוצה תו קניה בהנחה!'"/>
      </div>
    </div>
  </div>
</template>

<script>
import Segment from '@/Segment';
import BaseUI from '@riseupil/base-ui';
// eslint-disable-next-line max-len
import ExpandableSummaryRow from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/cashflow-view/generic-components/cashflow-types/cashflow-types-base/ExpandableSummaryRow';
import { mapActions } from 'vuex';
import * as LayoutConsts from '@riseupil/base-ui/src/utils/layout-consts';
import EventBus from '@/event-bus/event-bus';

export default {
  name: 'SuperDiscountPage',
  components: {
    RiseupButton: BaseUI.RiseupButton,
    ExpandableSummaryRow,
    Separator: BaseUI.Separator,
    ImageHeaderPage: BaseUI.Pages.ImageHeaderPage,
    Icon: BaseUI.Icon,
  },
  data() {
    return {
      variant: '',
      showAddToRamiLeviWaiting: true,
      showCopyCode: true,
    };
  },
  created() {
    this.variant = this.$route.query.group;
    this.setAppHeaderProps({
      backgroundColorVariant: LayoutConsts.COLOR_VARIANT.GREEN,
      logoColor: '#ffffff',
      textColor: '#ffffff',
    });
    Segment.trackUserInteraction('SuperDiscount_Entered', { variant: this.variant });
  },
  mounted() {
  },
  computed: {
    imageHeaderProps() {
      return {
        backgroundColor: BaseUI.LayoutConsts.COLOR_VARIANT.GREEN,
        imgHeight: 204,
        bottomSeparator: false,
      };
    },
    textColor() {
      return BaseUI.Colors.whiteText;
    },
    bgColorVariant() {
      return BaseUI.LayoutConsts.COLOR_VARIANT.LIGHT_BLUE;
    },
    colors() {
      return {
        green: BaseUI.Colors.riseupGreen,
        darkGreen: BaseUI.Colors.riseupDarkGreen,
        blue: BaseUI.Colors.riseupBlue,
        lightBlue: BaseUI.Colors.riseupLightBlue,
      };
    },
    organizationCode() {
      return '203310';
    },
  },
  methods: {
    ...mapActions('appHeader', ['setAppHeaderProps']),
    buttonClicked() {
      Segment.trackUserInteraction('SuperDiscountBuy_Clicked', { variant: this.variant });
      window.open('https://tavplus-business.mltp.co.il/', '_blank');
    },
    detailsExpanded() {
      setTimeout(() => {
        EventBus.$emit('scroll-bottom');
      }, 200);
      Segment.trackUserInteraction('SuperDiscountDetails_Clicked', { variant: this.variant });
    },
    termsClicked() {
      Segment.trackUserInteraction('SuperDiscountTerms_Clicked', { variant: this.variant });
      window.open('https://brand.carrefour.co.il/content/gift_card', '_blank');
    },
    addToWaitingList() {
      Segment.trackUserInteraction('SuperDiscountComingSoon_Clicked', { supermarket: 'RamiLevi' });
      this.showAddToRamiLeviWaiting = false;
    },
    async copyCode() {
      Segment.trackUserInteraction('SuperDiscountCopyCode_Clicked', { supermarket: 'Carefour' });
      await this.$copyText(this.organizationCode);
      this.showCopyCode = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@riseupil/base-ui/src/scss/riseup-colors";
.super-discount-content {
  .bold {
    font-weight: bold;
  }
  .simple-page-content {
    .text-line {
      margin-bottom: 24px;
    }
    .shops-header {
      margin-bottom: 10px;
    }
    .logos {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;
      .logo-container {
        width: 100px;
        height: 100px;
        padding: 17px;
        border: 1px solid #DDDDDD;
        border-radius: 7px;
        .logo {
          width: 100%;
        }
      }
    }
  }
  .summary-row {
    margin-top: 20px;
    border-top: 1px solid #DCDCDC;
  }
  .answer {
    margin-bottom: 20px;
    padding: 0 24px;
    .bullets{
      font-size: 18px;
      line-height: 26px;
      .link {
        font-weight: bold;
        color: $riseup_blue;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
  .separator {
    margin-bottom: 24px;
  }
  .sticky-container {
    box-shadow: 0px -6px 24px 0px #20212514;
    background-color: $riseup_white;
    position: sticky;
    bottom: 0;
    .button-container{
      padding: 0 24px 24px;
      .button {
        width: 100%;
      }
    }
  }
}

</style>
